import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/thank-you-donate.css"
import { ArrowLeftIcon } from "@heroicons/react/solid"

const ThankYouDonatePage = () => {

  return <Layout colored_header={true}>
    <Seo title="Thank you for your donation" />
    <div className="ty-donate-cont">
      <div className="content">
        <h1>Thank you for your donation</h1>
        <p>Thank you for your donation. We will get back to you as soon as we can.</p>
        <Link to="/donate/" className="btn-primary"><ArrowLeftIcon className="w-6 h-6 mr-5" /> Go Back</Link>
      </div>
    </div>

  </Layout>
}

export default ThankYouDonatePage